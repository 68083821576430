<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-5">
      <div>
        <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'G2PresupuestoVsRealXPeriodo',
  props: {
    datos: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    async datos(val) {
      this.elementVisible = false;
      let presupuesto = [];
      let real = [];
      let categorias = [];
      let formatPresupuesto = [];
      let formatReal = [];
      let formatCategorias = [];
      let ejecucion = [];
      let formatEjecucion = [];
      val.forEach((el) => {
        categorias.push(el.idsubarea);
        formatCategorias.push(el.subarea);
        presupuesto.push(Number.parseFloat(el.totalpresupuesto));
        real.push(Number.parseFloat(el.totalreal));
        formatPresupuesto.push(el.formatopresupuesto);
        formatReal.push(el.formatoreal);
        ejecucion.push(Number.parseFloat(el.porcentaje));
        formatEjecucion.push(el.formatoporcentaje);
      });
      this.series[0] = { ...this.series[0], data: presupuesto, format: formatPresupuesto };
      this.series[1] = { ...this.series[1], data: real, format: formatReal };
      this.series[2] = { ...this.series[2], data: ejecucion, format: formatEjecucion };
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            categories: categorias,
            formatCategories: formatCategorias,
            ...this.chartOptions.xaxis.labels
          }
        }
      };
      this.elementVisible = true;
    }
  },
  data: () => ({
    elementVisible: false,
    series: [
      { name: 'PPTADO', type: 'column', data: [], format: [] },
      { name: 'REAL', type: 'column', data: [], format: [] },
      { name: '% EJ. PPTO', type: 'line', data: [], format: [] }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: true
        },
        sparkline: {
          enabled: false
        }
      },
      title: {
        text: '% EJEC. PRESUPUESTO VS REAL POR ÁREA',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 3,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '48%',
          endingShape: 'flat'
        }
      },
      fill: {
        type: 'solid',
        opacity: 1
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, obj) {
          return val > 0 ? obj.w.config.series[obj.seriesIndex].format[obj.dataPointIndex] : '';
        },
        enabledOnSeries: [2],
        style: {
          fontSize: '12px',
          colors: ['#7f312f']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 10,
          borderWidth: 0,
          opacity: 1
        }
        /*background: {
          enabled: true,
          foreColor: '#7f312f',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#7f312f',
          opacity: 0,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: '#7f312f',
            opacity: 1
          }
        }*/
      },
      xaxis: {
        type: 'category',
        categories: [],
        formatCategories: [],
        labels: {
          trim: true,
          style: {
            fontSize: '10px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
        }
      },
      yaxis: [
        {
          seriesName: 'REAL',
          /*axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          title: {
            text: 'PRESUPUESTADO',
            style: {
              color: '#008FFB'
            }
          },
          tooltip: {
            enabled: true
          },*/
          labels: {
            style: {
              cssClass: 'text-muted fill-color'
            }
          }
        },
        {
          seriesName: 'PPTADO',
          show: false
          /*axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#00E396'
          },
          labels: {
            style: {
              colors: '#00E396'
            }
          },
          title: {
            text: 'REAL',
            style: {
              color: '#00E396'
            }
          },
          tooltip: {
            enabled: true
          }*/
        },
        {
          seriesName: '% EJ. PPTO',
          opposite: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true,
            color: '#fd625e'
          },
          labels: {
            style: {
              colors: '#fd625e'
            }
          },
          /*title: {
            text: '% EJ. PPTO',
            style: {
              color: '#FEB019'
            }
          },*/
          tooltip: {
            enabled: false
          }
        }
      ],
      tooltip: {
        x: {
          show: true,
          formatter: (val, opt) => {
            return opt.w.config.xaxis.formatCategories[val - 1];
          }
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '11px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      markers: {
        size: 0
      },
      stroke: {
        curve: 'straight',
        width: [1, 1, 4]
      },
      colors: ['#9fa6a7', '#007e49', '#fd625e']
    }
  }),
  components: {
    VueApexCharts
  }
};
</script>
